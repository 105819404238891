import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Helmet } from "react-helmet"
import Menu from "@/components/common/Menu"
import Lock from "@/components/common/Lock"
import AccountModal from "@/components/modals/Account"
import BoostModal from "@/components/modals/Boost"
import UnlockModal from "@/components/modals/Unlock"
import * as style from "./style.module.scss"

const MainLayout = ({ children, location }: { children: React.ReactNode; location: any }) => {
  return (
    <>
      <Helmet title="Pigz.io The Game">
        <meta
          name="viewport"
          content="width=device-width,viewport-fit=cover,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <link rel="stylesheet" type="text/css" href="/font/satoshi.css" />
        <link rel="stylesheet" type="text/css" href="/font/xray-icons.css" />
      </Helmet>
      <AccountModal />
      <BoostModal />
      <UnlockModal />
      <Lock />
      <div>{children}</div>
      <Menu />
    </>
  )
}

export default MainLayout
