import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs, message } from "antd"
import { tgUseBackButton } from "@/services/telegram"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import { unlockPig } from "@/services/api"
import * as style from "./style.module.scss"
import { AccountActions } from "@/redux/account"

const ModalBoost = () => {
  const dispatch = useAppDispatch()
  const remoteModel = useAppSelector((state) => state.account.remoteModel)
  const modalUnlock = useAppSelector((state) => state.app.modalUnlock)
  const launchParams = useAppSelector((state) => state.app.launchParams)
  const tgBackButton = tgUseBackButton(true)
  const pigToUnlock = remoteModel?.pigz[modalUnlock.id]
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    tgBackButton?.on("click", closeModal)
    return () => {
      tgBackButton?.off("click", closeModal)
    }
  }, [tgBackButton])

  useEffect(() => {
    modalUnlock ? tgBackButton?.show() : tgBackButton?.hide()
  }, [modalUnlock])

  const closeModal = () => {
    dispatch(AppActions.MODAL_UNLOCK_SET(false, -1))
  }

  const unlock = async () => {
    setLoading(true)
    const result = await unlockPig(launchParams?.initDataRaw!, pigToUnlock?.id!)
    dispatch(AccountActions.ACCOUNT_INFO_UPDATE_SAGA())
    setTimeout(() => {
      if (result.error) {
        message.error("Unlock failed. Try again later")
      } else {
        message.success("Pig successfully unlocked!")
      }
      setLoading(false)
      closeModal()
    }, 1000)
  }

  return (
    <Modal
      title={null}
      open={modalUnlock.opened}
      onCancel={() => closeModal()}
      footer={null}
      width={420}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      <Tabs defaultActiveKey="1" items={[{ key: "account", label: "Unlock Character" }]} />
      {pigToUnlock && (
        <div className="text-center">
          <h2 className="mb-0">{pigToUnlock?.name}</h2>
          <p className="text-muted mb-3">He's a good pig</p>
          <div className="mb-2">
            <img src={`/pigz/pics/${pigToUnlock.id}.svg`} alt={pigToUnlock.name} style={{ maxWidth: "330px" }} />
          </div>
          <div className="mb-2">
            <Button loading={loading} size="large" type="primary" onClick={() => unlock()}>
              <i className="xi xi-unlock" /> Unlock
            </Button>
          </div>
          <p className="text-muted font-size-14 mb-5">Keys: {remoteModel?.unlockKeys || 0}</p>
        </div>
      )}
    </Modal>
  )
}

export default ModalBoost
