import React from "react"
import { Link } from "gatsby"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { amountWithCommas } from "@/utils"
import * as style from "./style.module.scss"
import Emoji from "@/components/common/Emoji"

const Menu = () => {
  const remoteModel = useAppSelector((state) => state.account.remoteModel)

  return (
    <div className={style.menu}>
      <div className={style.menuInner}>
        <Link to="/" activeClassName={style.active}>
          <Emoji type="game" size={32} />
          Game
          <br />
        </Link>
        <Link to="/boosts" activeClassName={style.active}>
          <Emoji type="zap" size={32} />
          Boosts
          <br />
        </Link>
        <Link to="/referrals" activeClassName={style.active}>
          <div className={style.count}>{amountWithCommas(remoteModel?.refsCount || 0)}</div>
          <Emoji type="pig-face" size={32} />
          Referrals
          <br />
        </Link>
        <Link to="/leaders" activeClassName={style.active}>
          <Emoji type="winner" size={32} />
          Leaders
          <br />
        </Link>
      </div>
    </div>
  )
}

export default Menu
