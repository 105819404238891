import React from "react"
import { Switch } from "antd"
import Emoji from "@/components/common/Emoji"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions } from "@/redux/app"
import * as style from "./style.module.scss"

const Settings = () => {
  const dispatch = useAppDispatch()
  const soundMusic = useAppSelector((state) => state.app.soundMusic)
  const soundEffects = useAppSelector((state) => state.app.soundEffects)
  const hapticFeedback = useAppSelector((state) => state.app.hapticFeedback)
  const confettiEffect = useAppSelector((state) => state.app.confettiEffect)

  return (
    <div>
      <div>
        <div className="xray-form">
          <div className="xray-form-item">
            <div className="xray-form-item-label">Vibration Feedback</div>
            <div className="xray-form-item-dots" />
            <div className="xray-form-item-control">
              <Switch
                value={hapticFeedback}
                onChange={(value) => {
                  dispatch(AppActions.HAPTIC_FEEDBACK_SET(value))
                }}
              />
            </div>
          </div>
          <div className="xray-form-item">
            <div className="xray-form-item-label">Confetti Effect</div>
            <div className="xray-form-item-dots" />
            <div className="xray-form-item-control">
              <Switch
                value={confettiEffect}
                onChange={(value) => {
                  dispatch(AppActions.CONFETTI_EFFECT_SET(value))
                }}
              />
            </div>
          </div>
          <div className="xray-form-item">
            <div className="xray-form-item-label">Music</div>
            <div className="xray-form-item-dots" />
            <div className="xray-form-item-control">
              <Switch
                value={soundMusic}
                onChange={(value) => {
                  dispatch(AppActions.SOUND_MUSIC_SET(value))
                }}
              />
            </div>
          </div>
          <div className="xray-form-item">
            <div className="xray-form-item-label">Sound Effects</div>
            <div className="xray-form-item-dots" />
            <div className="xray-form-item-control">
              <Switch
                value={soundEffects}
                onChange={(value) => {
                  dispatch(AppActions.SOUND_EFFECTS_SET(value))
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
