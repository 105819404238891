export const getAccount = async (initDataRaw: string) => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/model`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
    }),
  })
  return await result.json()
}

export const commitClicks = async (initDataRaw: string, clicksPigBase: number, clicksPigStarred: number) => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/commit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
      clicksPigBase,
      clicksPigStarred,
    }),
  })
  return await result.json()
}

export const unlockPig = async (initDataRaw: string, pigId: number) => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/unlock_pig`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
      pigId,
    }),
  })
  return await result.json()
}

export const getAccountPosition = async (id: number) => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/stats/account/position?id=${id}`)
  return await result.json()
}

export const getAccountsRanking = async () => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/stats/game/ranking`)
  return await result.json()
}

export const getStats = async () => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/stats/game/stats`)
  return await result.json()
}
