import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs } from "antd"
import { tgUseBackButton } from "@/services/telegram"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import Account from "./Account"
import Settings from "./Settings"
import About from "./About"
import * as style from "./style.module.scss"

const ModalAccount = () => {
  const dispatch = useAppDispatch()
  const modalAccount = useAppSelector((state) => state.app.modalAccount)
  const tgBackButton = tgUseBackButton(true)

  useEffect(() => {
    tgBackButton?.on("click", closeModal)
    return () => {
      tgBackButton?.off("click", closeModal)
    }
  }, [tgBackButton])

  useEffect(() => {
    modalAccount ? tgBackButton?.show() : tgBackButton?.hide()
  }, [modalAccount])

  const closeModal = () => {
    dispatch(AppActions.MODAL_ACCOUNT_SET(false))
  }

  return (
    <Modal
      title={null}
      open={modalAccount}
      onCancel={() => closeModal()}
      footer={null}
      width={420}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          { key: "account", label: "Account", children: <Account /> },
          { key: "settings", label: "Settings", children: <Settings /> },
          { key: "about", label: "About", children: <About /> },
        ]}
      />
    </Modal>
  )
}

export default ModalAccount
