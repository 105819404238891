import React, { useEffect, useState } from "react"
import { useAppSelector, useAppDispatch } from "./provider"
import { AccountActions, AccountTypes } from "./account"
import { AppActions, AppTypes } from "./app"
import { COMMIT_INTERVAL } from "@/config"
import { set } from "store"

let soundTrackStarted: boolean = false

const Runner = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch()
  const selectedPigId = useAppSelector((state) => state.account.selectedPigId)
  const remoteModel = useAppSelector((state) => state.account.remoteModel)
  const soundMusic = useAppSelector((state) => state.app.soundMusic)
  const [soundTrack, setSoundtrack] = useState<HTMLAudioElement | null>(null)

  // Music on/off
  useEffect(() => {
    if (soundMusic) {
      if (soundTrack) {
        soundTrack.currentTime = 0
        soundTrack.play()
      } else {
        const sound = new Audio("/audio/theme-01.mp3")
        sound.volume = 0.2
        sound.currentTime = 0
        sound.addEventListener("ended", () => {
          sound.currentTime = 0
          sound.play()
        })
        document.body.addEventListener("click", () => {
          if (!soundTrackStarted) {
            sound.play()
            soundTrackStarted = true
          }
        })
        setSoundtrack(sound)
      }
    } else {
      soundTrack?.pause()
    }
  }, [soundMusic])

  // Account info update
  useEffect(() => {
    dispatch(AccountActions.ACCOUNT_INFO_UPDATE_SAGA())
  }, [])

  // Commit taps interval
  useEffect(() => {
    setInterval(() => {
      dispatch(AccountActions.COMMIT_CLICKS_SAGA())
    }, COMMIT_INTERVAL)
  }, [])

  // Update state on pig change
  useEffect(() => {
    if (remoteModel) {
      const isStarredPigSelected = selectedPigId === remoteModel.starredPigId
      dispatch(AccountActions.IS_STARRED_PIG_SELECTED_SET(isStarredPigSelected))
      isStarredPigSelected
        ? dispatch(
            AccountActions.PAY_PER_CLICK_SET(
              remoteModel.reward.starred.xcoin,
              remoteModel?.reward.starred.multiplier || 0
            )
          )
        : dispatch(
            AccountActions.PAY_PER_CLICK_SET(remoteModel.reward.base.xcoin, remoteModel?.reward.base.multiplier || 0)
          )
    }
  }, [selectedPigId, remoteModel])

  return children
}

export default Runner
