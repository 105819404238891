import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs } from "antd"
import { tgUseBackButton } from "@/services/telegram"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import * as style from "./style.module.scss"

const ModalBoost = () => {
  const dispatch = useAppDispatch()
  const modalBoost = useAppSelector((state) => state.app.modalBoost)
  const tgBackButton = tgUseBackButton(true)

  useEffect(() => {
    tgBackButton?.on("click", closeModal)
    return () => {
      tgBackButton?.off("click", closeModal)
    }
  }, [tgBackButton])

  useEffect(() => {
    modalBoost ? tgBackButton?.show() : tgBackButton?.hide()
  }, [modalBoost])

  const closeModal = () => {
    dispatch(AppActions.MODAL_BOOST_SET(false))
  }

  return (
    <Modal
      title={null}
      open={modalBoost}
      onCancel={() => closeModal()}
      footer={null}
      width={420}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      <Tabs defaultActiveKey="1" items={[{ key: "account", label: "Boost Power" }]} />
      <h2 className="mb-3">Multipliers</h2>
      <div className="text-muted">
        <p>
          Boost Multipliers are your key to skyrocketing your progress! Earn these powerful boosts through achievements,
          upgrades, or special challenges.
        </p>
        <p>
          Activate them to multiply your clicks and watch your score soar. Keep an eye out for opportunities to activate
          your Boost Multipliers and maximize your rewards!
        </p>
        <p>
          <strong className="text-mono">Multipliers table</strong>
          <div className="xray-block py-5 mt-3 text-center">
            The final version will be available after the beta test is completed. Follow updates on social networks
          </div>
        </p>
      </div>
    </Modal>
  )
}

export default ModalBoost
