import React from "react"
import { Button } from "antd"
import Emoji from "@/components/common/Emoji"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import * as style from "./style.module.scss"

const Account = () => {
  const remoteModel = useAppSelector((state) => state.account.remoteModel)

  return (
    <div>
      <div className="mb-4">
        <div className="xray-form">
          <div className="xray-form-item">
            <div className="xray-form-item-label">Account ID</div>
            <div className="xray-form-item-dots" />
            <div className="xray-form-item-control text-mono">{remoteModel?.id || "—"}</div>
          </div>
          <div className="xray-form-item">
            <div className="xray-form-item-label">Unlock Keys</div>
            <div className="xray-form-item-dots" />
            <div className="xray-form-item-control text-mono">{remoteModel?.unlockKeys || 0}</div>
          </div>
        </div>
      </div>
      <h5 className="mb-3">Cardano Wallet</h5>
      <div>
        <div className={style.box}>
          <div className={style.wallet}>
            <i className="xi xi-wallet" />
          </div>
          <div>Not Connected</div>
          <div className="mb-0">Soon™</div>
        </div>
        <div>
          <Button className="xray-btn-round me-2" type="primary" disabled>
            <i className="xi xi-add" />
            Connect Wallet
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Account
