import React from "react"
import Emoji from "@/components/common/Emoji"
import * as style from "./style.module.scss"

const About = () => {
  return (
    <div>
      <h2 className="mb-3">
        Hello, Pigz.io The Game Enthusiasts! <Emoji type="pig-nose" />
      </h2>
      <div className="text-muted">
        <p>
          We're excited to introduce you to Pigz.io, an addictive and thrilling game where your speed and strategy are
          put to the test. Built and managed with the best blockchain ever:
          <br />
          <strong className="text-mono font-size-21">Cardano! ™</strong>
        </p>
        <p>
          In Pigz.io The Game, your goal is simple: <br />
          <strong className="text-mono font-size-21">Press Pig to Win! ™</strong>
        </p>
        <p>Prepare your finger and get ready to:</p>
        <ul style={{ paddingLeft: 25 }}>
          <li>
            <strong className="text-mono">Endless Clicking Fun:</strong> Test your speed and stamina in this addictive
            clicker game
          </li>
          <li>
            <strong className="text-mono">Earn XCOIN:</strong> Collect XCOIN with each click and watch your fortune grow
          </li>
          <li>
            <strong className="text-mono">XDIAMOND:</strong> Get a rare NFT that unlocks unique mechanics
          </li>
          <li>
            <strong className="text-mono">Leaderboards:</strong> Compete with friends and players worldwide
          </li>
          <li>
            <strong className="text-mono">Piglets:</strong> Unlock new characters that will help you achieve your goals
          </li>
          <li>
            <strong className="text-mono">Upgrades:</strong> Unlock awesome upgrades and boost your power
          </li>
        </ul>
        <p>Join the fun and start the way to XCOIN wealth. The more you click, the richer you get!</p>
        <p>
          Happy clicking,
          <br />
          The Pigz.io Team
        </p>
      </div>
    </div>
  )
}

export default About
